<template>
  <b-modal
    :id="id"
    size="xl"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateNoise"
    @hidden="resetState"
    @show="fetchDataCombobox"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin vị trí
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Vị trí lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="position"
                rules="required"
                :custom-messages="{required: `Vị trí lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.position"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Loại quy chuẩn <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="noiseLevelId"
                rules="required"
                :custom-messages="{required: `Loại quy chuẩn là bắt buộc`}"
              >
                <v-select
                  v-model="dataInput.noiseLevelId"
                  :reduce="title => title.key"
                  label="value"
                  :options="dataCombobox || []"
                  :placeholder="'Lựa chọn loại quy chuẩn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Mức âm hoặc mức âm tương đương không quá dBA <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="noiseDba"
                rules="required"
                :custom-messages="{required: `Mức âm hoặc mức âm tương đương không quá dBA là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.noiseDba"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="Volume2Icon"
            class="mr-75"
          />
          <span>
            Mức âm dB ở các dải ôc-ta với tần số trung bình nhân (Hz) không vượt quá dB
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">63 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s63"
                rules="required"
                :custom-messages="{required: `63 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s63"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">125 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s125"
                rules="required"
                :custom-messages="{required: `125 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s125"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">250 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s250"
                rules="required"
                :custom-messages="{required: `250 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s250"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">500 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s500"
                rules="required"
                :custom-messages="{required: `500 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s500"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">1000 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s1000"
                rules="required"
                :custom-messages="{required: `1000 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s1000"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">2000 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s2000"
                rules="required"
                :custom-messages="{required: `2000 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s2000"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">4000 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s4000"
                rules="required"
                :custom-messages="{required: `4000 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s4000"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">8000 <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="s8000"
                rules="required"
                :custom-messages="{required: `8000 là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.s8000"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataNoiseId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        noiseLevelId: '',
        position: '',
        noiseDba: 0,
        s63: 0,
        s125: 0,
        s250: 0,
        s500: 0,
        s1000: 0,
        s2000: 0,
        s4000: 0,
        s8000: 0,
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataNoiseId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          noiseLevelId: this.dataNoiseId.noiseLevelId,
          position: this.dataNoiseId.position,
          noiseDba: this.dataNoiseId.noiseDba,
          s63: this.dataNoiseId.s63,
          s125: this.dataNoiseId.s125,
          s250: this.dataNoiseId.s250,
          s500: this.dataNoiseId.s500,
          s1000: this.dataNoiseId.s1000,
          s2000: this.dataNoiseId.s2000,
          s4000: this.dataNoiseId.s4000,
          s8000: this.dataNoiseId.s8000,
        }
      }
    },
  },
  methods: {
    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_MONITOR_NOISE)
      this.dataCombobox = data?.data
    },

    // action tạo yếu tố thời tiết
    handleCreateNoise(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateNoise', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        noiseLevelId: '',
        position: '',
        noiseDba: 0,
        s63: 0,
        s125: 0,
        s250: 0,
        s500: 0,
        s1000: 0,
        s2000: 0,
        s4000: 0,
        s8000: 0,
      }
    },
  },
}
</script>
