<template>
  <b-modal
    :id="id"
    size="lg"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateShake"
    @hidden="resetState"
    @show="fetchDataCombobox"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin vị trí
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Vị trí lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="position"
                rules="required"
                :custom-messages="{required: `Vị trí lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.position"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Loại quy chuẩn <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="vibrateLevelId"
                rules="required"
                :custom-messages="{required: `Loại quy chuẩn là bắt buộc`}"
              >
                <v-select
                  v-model="dataInput.vibrateLevelId"
                  :reduce="title => title.key"
                  label="value"
                  :options="dataCombobox || []"
                  :placeholder="'Lựa chọn loại quy chuẩn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Dải tần rung <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="{required: `Dải tần rung là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="Volume2Icon"
            class="mr-75"
          />
          <span>
            Vận tốc rung
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Rung đứng <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="standingVibration"
                rules="required"
                :custom-messages="{required: `Rung đứng là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.standingVibration"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Rung ngang <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="horizontalVibration"
                rules="required"
                :custom-messages="{required: `Rung ngang là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.horizontalVibration"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataShakeId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        vibrateLevelId: '',
        position: '',
        name: '',
        standingVibration: 0,
        horizontalVibration: 0,
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataShakeId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          vibrateLevelId: this.dataShakeId.vibrateLevelId,
          position: this.dataShakeId.position,
          name: this.dataShakeId.name,
          standingVibration: this.dataShakeId.standingVibration,
          horizontalVibration: this.dataShakeId.horizontalVibration,
        }
      }
    },
  },
  methods: {
    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_SHAKE)
      this.dataCombobox = data?.data
    },

    // action tạo yếu tố thời tiết
    handleCreateShake(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateShake', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        vibrateLevelId: '',
        position: '',
        name: '',
        standingVibration: 0,
        horizontalVibration: 0,
      }
    },
  },
}
</script>
