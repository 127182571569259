<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm vị trí'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th><b-form-checkbox @change="selectAllCheckbox($event)" /></b-th>
          <b-th colspan="1">
            VỊ TRÍ LAO ĐỘNG
          </b-th>
          <b-th colspan="1">
            LOẠI BỤI
          </b-th>
          <b-th colspan="1">
            HÀM LƯỢNG
          </b-th>
          <b-th colspan="2">
            Nồng độ bụi toàn phần
          </b-th>
          <b-th colspan="2">
            Nồng độ bụi hô hấp
          </b-th>
          <b-th colspan="1">
            CHỨC NĂNG
          </b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>Lấy theo ca</b-th>
          <b-th>Lấy theo thời điểm</b-th>
          <b-th>Lấy theo ca</b-th>
          <b-th>Lấy theo thời điểm</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in dataTable"
          :key="index"
        >
          <b-td class="pb-25">
            <b-form-checkbox
              :checked="checkId.includes(item.id)"
              @change="selectRowTable($event, item.id)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.position }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.dustLevelName }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.content }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.fullShift }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.respiratoryShift }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.fullTime }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.respiratoryTime }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <feather-icon
              icon="Edit3Icon"
              size="18"
              class="text-body ml-2"
              @click="showModalEdit(item.id)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- Modal tạo tiếng ồn -->
    <edit-dust
      :id="modalEditDust"
      :type="modalType"
      :dataDustId="dataDustId"
      @handleCreateDust="handleCreateDust"
    />

  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormCheckbox,
} from 'bootstrap-vue'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import EditDust from './modal-create/EditDust.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    ButtonAllHeader,
    BFormCheckbox,
    EditDust,
    ConfirmModal,
    MyPagination,
  },
  data() {
    return {
      dataTable: [],
      totalRecord: 0,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
        observationMonitorId: this.$route.params.id,
      },
      showBtnMultiDelete: false,
      modalEditDust: 'confirm-edit-dust',
      modalType: null,
      dustId: null,
      dataDustId: {},
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      checkId: [],
    }
  },
  created() {
    this.fetchTableDust(this.urlQuery)
  },
  methods: {
    async fetchTableDust(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_DUST, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.showBtnMultiDelete = false
    },
    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalEditDust)
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.dustId = id
      this.$bvModal.show(this.modalEditDust)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_DUST}${this.dustId}`)
      this.dataDustId = data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchTableDust(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchTableDust(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchTableDust(this.urlQuery)
      }
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(event, val) {
      if (event === true) {
        this.checkId.push(val)
      }
      if (event === false) {
        const a = this.checkId.findIndex(x => x === val)
        this.checkId.splice(a, 1)
      }
      if (this.checkId.length > 0) {
        this.showBtnMultiDelete = true
      } else if (this.checkId.length < 1 && event === false) {
        this.showBtnMultiDelete = false
      }
    },

    // Chọn xóa tất cả
    selectAllCheckbox(event) {
      if (event === true) {
        this.showBtnMultiDelete = true
        this.checkId = this.dataTable.map(element => element.id)
      } else if (event === false) {
        this.showBtnMultiDelete = false
        this.checkId = []
      }
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.checkId
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.delete(ConstantsApi.DELETE_DUST, {
        params: {
          model: JSON.stringify(this.deleteIds),
          observationMonitorId: this.$route.params.id,
        },
      }).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalEditDust)
        this.fetchTableDust(this.urlQuery)
        this.checkId = []
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateDust(val) {
      const payload = {
        ...val,
        observationMonitorId: this.$route.params.id,
        fullShift: parseInt(val.fullShift, 10),
        fullTime: parseInt(val.fullTime, 10),
        respiratoryShift: parseInt(val.respiratoryShift, 10),
        respiratoryTime: parseInt(val.respiratoryTime, 10),
      }
      if (this.modalType === 'edit') {
        const data = {
          ...payload,
          id: this.dustId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_DUST, data).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Cập nhật thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchTableDust(this.urlQuery)
            this.$bvModal.hide(this.modalEditDust)
            this.checkId = []
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_DUST, payload).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditDust)
            this.fetchTableDust(this.urlQuery)
            this.checkId = []
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
}
</style>
