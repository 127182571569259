<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateJob"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form>
        <b-form-group
          label-for="code"
        >
          <label for="code">Vị trí việc làm <span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="position"
            rules="required"
            :custom-messages="{required: `Vị trí việc làm là bắt buộc`}"
          >
            <b-form-input
              v-model="dataInput.position"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="code"
        >
          <label for="code">Mô tả nội dung công việc <span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="content"
            rules="required"
            :custom-messages="{required: `Mô tả nội dung công việc là bắt buộc`}"
          >
            <b-form-input
              v-model="dataInput.content"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="code"
        >
          <label for="code">Số lượng người tiếp xúc <span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="numberOfContacts"
            rules="required"
            :custom-messages="{required: `Số lượng người tiếp xúc là bắt buộc`}"
          >
            <b-form-input
              v-model="dataInput.numberOfContacts"
              type="number"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="code"
        >
          <label for="code">Yếu tố tiếp xúc <span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="contactFactor"
            rules="required"
            :custom-messages="{required: `Yếu tố tiếp xúc là bắt buộc`}"
          >
            <b-form-input
              v-model="dataInput.contactFactor"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="code"
        >
          <label for="code">Bệnh nghề nghiệp có thể phát sinh <span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="occupationalDisease"
            rules="required"
            :custom-messages="{required: `Bệnh nghề nghiệp có thể phát sinh là bắt buộc`}"
          >
            <b-form-input
              v-model="dataInput.occupationalDisease"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataJobId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        position: '',
        content: '',
        numberOfContacts: 0,
        contactFactor: '',
        occupationalDisease: '',
      },
    }
  },
  watch: {
    dataJobId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          position: this.dataJobId.position,
          content: this.dataJobId.content,
          numberOfContacts: this.dataJobId.numberOfContacts,
          contactFactor: this.dataJobId.contactFactor,
          occupationalDisease: this.dataJobId.occupationalDisease,
        }
      }
    },
  },
  methods: {

    // action tạo yếu tố thời tiết
    handleCreateJob(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateJob', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        position: '',
        content: '',
        numberOfContacts: 0,
        contactFactor: '',
        occupationalDisease: '',
      }
    },
  },
}
</script>
