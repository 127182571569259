<template>
  <b-modal
    :id="id"
    size="lg"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateClimate"
    @hidden="resetState"
    @show="fetchDataCombobox"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin vị trí
          </span>
        </h3>
        <b-row>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Vị trí quan trắc <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="position"
                rules="required"
                :custom-messages="{required: `Vị trí quan trắc là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.position"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Loại quy chuẩn <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="temperatureLevelId"
                rules="required"
                :custom-messages="{required: `Loại quy chuẩn là bắt buộc`}"
              >
                <v-select
                  v-model="dataInput.temperatureLevelId"
                  :reduce="title => title.key"
                  label="value"
                  :options="dataCombobox || []"
                  :placeholder="'Lựa chọn loại quy chuẩn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="ThermometerIcon"
            class="mr-75"
          />
          <span>
            Nhiệt độ
          </span>
        </h3>
        <b-row>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Nhiệt độ số mẫu đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="temperatureReachedOk"
                rules="required"
                :custom-messages="{required: `Nhiệt độ số mẫu đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.temperatureReachedOk"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Nhiệt độ số mẫu không đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="temperatureReachedNot"
                rules="required"
                :custom-messages="{required: `Nhiệt độ số mẫu không đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.temperatureReachedNot"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="CloudDrizzleIcon"
            class="mr-75"
          />
          <span>
            Độ ẩm
          </span>
        </h3>
        <b-row>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Độ ẩm số mẫu đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="humidityReachedOk"
                rules="required"
                :custom-messages="{required: `Độ ẩm số mẫu đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.humidityReachedOk"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Độ ẩm số mẫu không đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="humidityReachedNot"
                rules="required"
                :custom-messages="{required: `Độ ẩm số mẫu không đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.humidityReachedNot"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="WindIcon"
            class="mr-75"
          />
          <span>
            Tốc độ gió
          </span>
        </h3>
        <b-row>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Tốc độ gió số mẫu đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="windSpeedReachedOk"
                rules="required"
                :custom-messages="{required: `Tốc độ gió số mẫu đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.windSpeedReachedOk"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Tốc độ gió số mẫu không đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="windSpeedReachedNot"
                rules="required"
                :custom-messages="{required: `Tốc độ gió số mẫu không đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.windSpeedReachedNot"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="ThermometerIcon"
            class="mr-75"
          />
          <span>
            Bức xạ nhiệt
          </span>
        </h3>
        <b-row>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Bức xạ nhiệt số mẫu đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="heatRadiationReachedOk"
                rules="required"
                :custom-messages="{required: `Bức xạ nhiệt số mẫu đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.heatRadiationReachedOk"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Bức xạ nhiệt số mẫu không đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="heatRadiationReachedNot"
                rules="required"
                :custom-messages="{required: `Bức xạ nhiệt số mẫu không đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.heatRadiationReachedNot"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataClimateId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        temperatureLevelId: '',
        position: '',
        temperatureReachedOk: 0,
        temperatureReachedNot: 0,
        humidityReachedOk: 0,
        humidityReachedNot: 0,
        windSpeedReachedOk: 0,
        windSpeedReachedNot: 0,
        heatRadiationReachedOk: 0,
        heatRadiationReachedNot: 0,
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataClimateId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          temperatureLevelId: this.dataClimateId.temperatureLevelId,
          position: this.dataClimateId.position,
          temperatureReachedOk: this.dataClimateId.temperatureReachedOk,
          temperatureReachedNot: this.dataClimateId.temperatureReachedNot,
          humidityReachedOk: this.dataClimateId.humidityReachedOk,
          humidityReachedNot: this.dataClimateId.humidityReachedNot,
          windSpeedReachedOk: this.dataClimateId.windSpeedReachedOk,
          windSpeedReachedNot: this.dataClimateId.windSpeedReachedNot,
          heatRadiationReachedOk: this.dataClimateId.heatRadiationReachedOk,
          heatRadiationReachedNot: this.dataClimateId.heatRadiationReachedNot,
        }
      }
    },
  },
  methods: {
    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_MONITOR_CLIMATE)
      this.dataCombobox = data?.data
    },

    // action tạo yếu tố thời tiết
    handleCreateClimate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateClimate', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        temperatureLevelId: '',
        position: '',
        temperatureReachedOk: 0,
        temperatureReachedNot: 0,
        humidityReachedOk: 0,
        humidityReachedNot: 0,
        windSpeedReachedOk: 0,
        windSpeedReachedNot: 0,
        heatRadiationReachedOk: 0,
        heatRadiationReachedNot: 0,
      }
    },
  },
}
</script>
