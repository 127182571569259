<template>
  <div>
    <span>Đánh giá</span>
    <b-form-textarea
      v-model="dataInfo.economy"
      no-resize
      rows="4"
    />
  </div>
</template>

<script>
import {
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
  },
  props: {
    dataInfo: {
      type: Object,
      default: _ => {},
    },
  },
}
</script>
