export default {
  LIST_OBSERVATION_MONITOR: 'ObservationMonitor/get-paging',
  DELETE_OBSERVATION_MONITOR: 'ObservationMonitor/delete',
  DETAIL_OBSERVATION_MONITOR: 'ObservationMonitor/get-by-id/',
  CREATE_OBSERVATION_MONITOR: 'ObservationMonitor/create',
  EDIT_OBSERVATION_MONITOR: 'ObservationMonitor/update',
  IMPORT_FILE_MONITOR: 'ObservationMonitor/import-observation-monitor-from-json',
  LIST_MONITOR_CLIMATE: 'ObservationMonitor/get-paging-climate',
  CREATE_MONITOR_CLIMATE: 'ObservationMonitor/create-climate',
  EDIT_MONITOR_CLIMATE: 'ObservationMonitor/update-climate',
  DETAIL_MONITOR_CLIMATE: 'ObservationMonitor/get-by-id-climate/',
  DELETE_MONITOR_CLIMATE: 'ObservationMonitor/delete-climate',
  COMBOBOX_MONITOR_CLIMATE: 'ObservationMonitor/get-combobox-climate',
  LIST_MONITOR_LIGHT: 'ObservationMonitor/get-paging-light',
  CREATE_MONITOR_LIGHT: 'ObservationMonitor/create-light',
  EDIT_MONITOR_LIGHT: 'ObservationMonitor/update-light',
  DETAIL_MONITOR_LIGHT: 'ObservationMonitor/get-by-id-light/',
  DELETE_MONITOR_LIGHT: 'ObservationMonitor/delete-light',
  COMBOBOX_MONITOR_LIGHT: 'ObservationMonitor/get-combobox-light',
  LIST_MONITOR_NOISE: 'ObservationMonitor/get-paging-noise',
  CREATE_MONITOR_NOISE: 'ObservationMonitor/create-noise',
  EDIT_MONITOR_NOISE: 'ObservationMonitor/update-noise',
  DETAIL_MONITOR_NOISE: 'ObservationMonitor/get-by-id-noise/',
  DELETE_MONITOR_NOISE: 'ObservationMonitor/delete-noise',
  COMBOBOX_MONITOR_NOISE: 'ObservationMonitor/get-combobox-noise',
  LIST_SHAKE: 'ObservationMonitor/get-paging-vibrate',
  CREATE_SHAKE: 'ObservationMonitor/create-vibrate',
  EDIT_SHAKE: 'ObservationMonitor/update-vibrate',
  DETAIL_SHAKE: 'ObservationMonitor/get-by-id-vibrate/',
  DELETE_SHAKE: 'ObservationMonitor/delete-vibrate',
  COMBOBOX_SHAKE: 'ObservationMonitor/get-combobox-vibrate',
  LIST_DUST: 'ObservationMonitor/get-paging-dust',
  CREATE_DUST: 'ObservationMonitor/create-dust',
  EDIT_DUST: 'ObservationMonitor/update-dust',
  DETAIL_DUST: 'ObservationMonitor/get-by-id-dust/',
  DELETE_DUST: 'ObservationMonitor/delete-dust',
  COMBOBOX_DUST: 'ObservationMonitor/get-combobox-dust',
  LIST_POISON: 'ObservationMonitor/get-paging-poison',
  CREATE_POISON: 'ObservationMonitor/create-poison',
  EDIT_POISON: 'ObservationMonitor/update-poison',
  DETAIL_POISON: 'ObservationMonitor/get-by-id-poison/',
  DELETE_POISON: 'ObservationMonitor/delete-poison',
  COMBOBOX_POISON: 'ObservationMonitor/get-combobox-poison',
  LIST_RADATION: 'ObservationMonitor/get-paging-radation',
  CREATE_RADATION: 'ObservationMonitor/create-radation',
  EDIT_RADATION: 'ObservationMonitor/update-radation',
  DETAIL_RADATION: 'ObservationMonitor/get-by-id-radation/',
  DELETE_RADATION: 'ObservationMonitor/delete-noise',
  COMBOBOX_RADATION: 'ObservationMonitor/get-combobox-radiation',
  COMBOBOX_MAGNETIC: 'ObservationMonitor/get-combobox-magnetic',
  LIST_JOB: 'ObservationMonitor/get-paging-job',
  CREATE_JOB: 'ObservationMonitor/create-job',
  EDIT_JOB: 'ObservationMonitor/update-job',
  DETAIL_JOB: 'ObservationMonitor/get-by-id-job/',
  DELETE_JOB: 'ObservationMonitor/delete-job',
  LIST_OTHER: 'ObservationMonitor/get-paging-another-factor',
  CREATE_OTHER: 'ObservationMonitor/create-another-factor',
  EDIT_OTHER: 'ObservationMonitor/update-another-factor',
  DETAIL_OTHER: 'ObservationMonitor/get-by-id-another-factor/',
  DELETE_OTHER: 'ObservationMonitor/delete-another-factor',
  COMBOBOX_OTHER: 'ObservationMonitor/get-combobox-another',
}
