var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"md","title":"Thêm vị trí","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateJob,"hidden":_vm.resetState}},[_c('validation-observer',{ref:"rule"},[_c('b-form',[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Vị trí việc làm "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"position","rules":"required","custom-messages":{required: "Vị trí việc làm là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.position),callback:function ($$v) {_vm.$set(_vm.dataInput, "position", $$v)},expression:"dataInput.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Mô tả nội dung công việc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"content","rules":"required","custom-messages":{required: "Mô tả nội dung công việc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.content),callback:function ($$v) {_vm.$set(_vm.dataInput, "content", $$v)},expression:"dataInput.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số lượng người tiếp xúc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"numberOfContacts","rules":"required","custom-messages":{required: "Số lượng người tiếp xúc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.numberOfContacts),callback:function ($$v) {_vm.$set(_vm.dataInput, "numberOfContacts", $$v)},expression:"dataInput.numberOfContacts"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Yếu tố tiếp xúc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"contactFactor","rules":"required","custom-messages":{required: "Yếu tố tiếp xúc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.contactFactor),callback:function ($$v) {_vm.$set(_vm.dataInput, "contactFactor", $$v)},expression:"dataInput.contactFactor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Bệnh nghề nghiệp có thể phát sinh "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"occupationalDisease","rules":"required","custom-messages":{required: "Bệnh nghề nghiệp có thể phát sinh là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.occupationalDisease),callback:function ($$v) {_vm.$set(_vm.dataInput, "occupationalDisease", $$v)},expression:"dataInput.occupationalDisease"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }