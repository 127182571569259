<template>
  <b-modal
    :id="id"
    size="lg"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateOther"
    @hidden="resetState"
    @show="fetchDataCombobox"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin vị trí
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Vị trí lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="position"
                rules="required"
                :custom-messages="{required: `Vị trí lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.position"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="AlertTriangleIcon"
            class="mr-75"
          />
          <span>
            Thông tin hơi khí độc
          </span>
        </h3>
        <div
          v-for="(item, index) in dataInput.detailModals"
          :key="index"
        >
          <span
            v-if="dataInput.detailModals.length > 1"
            class="d-flex justify-content-end cursor-pointer"
            @click="dataInput.detailModals.splice(index, 1)"
          >
            <feather-icon
              size="16"
              icon="XIcon"
              class="mr-75"
            />
          </span>
          <b-row>
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="code"
              >
                <label for="code">Loại khí độc <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="anotherFactorId"
                  rules="required"
                  :custom-messages="{required: `Loại khí độc là bắt buộc`}"
                >
                  <v-select
                    v-model="item.anotherFactorId"
                    :reduce="title => title.key"
                    label="value"
                    :options="dataCombobox || []"
                    :placeholder="'Lựa chọn loại khí độc'"
                    :state="errors.length > 0 ? false:null"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="code"
              >
                <label for="code">Số mẫu đạt <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="reachedOk"
                  rules="required"
                  :custom-messages="{required: `Số mẫu đạt là bắt buộc`}"
                >
                  <b-form-input
                    v-model="item.reachedOk"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="code"
              >
                <label for="code">Số mẫu không đạt <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="reachedNot"
                  rules="required"
                  :custom-messages="{required: `Số mẫu không đạt là bắt buộc`}"
                >
                  <b-form-input
                    v-model="item.reachedNot"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div
          class="mt-1 cursor-pointer"
          @click="addInfoPosion"
        >
          <feather-icon
            size="20"
            icon="PlusCircleIcon"
            class="mr-75 text-primary"
          />
          <span class="text-primary">
            Thêm hơi khí độc
          </span>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataOtherId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        position: '',
        detailModals: [
          {
            anotherFactorId: '',
            reachedOk: 0,
            reachedNot: 0,
          },
        ],
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataOtherId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          position: this.dataOtherId.position,
          detailModals: this.dataOtherId.detailModals,
        }
      }
    },
  },
  methods: {
    addInfoPosion() {
      this.dataInput.detailModals = [...this.dataInput.detailModals, {
        anotherFactorId: '',
        reachedOk: 0,
        reachedNot: 0,
      }]
    },

    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_OTHER)
      this.dataCombobox = data?.data
    },

    // action tạo yếu tố thời tiết
    handleCreateOther(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateOther', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        position: '',
        detailModals: [
          {
            anotherFactorId: '',
            reachedOk: 0,
            reachedNot: 0,
          },
        ],
      }
    },
  },
}
</script>
