<template>
  <b-modal
    :id="id"
    size="lg"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateDust"
    @hidden="resetState"
    @show="fetchDataCombobox"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin vị trí
          </span>
        </h3>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Vị trí lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="position"
                rules="required"
                :custom-messages="{required: `Vị trí lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.position"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="CloudSnowIcon"
            class="mr-75"
          />
          <span>
            Thông tin chi tiết bụi
          </span>
        </h3>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Loại bụi <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="dustType"
                rules="required"
                :custom-messages="{required: `Loại bụi là bắt buộc`}"
              >
                <v-select
                  v-model="dataInput.dustType"
                  :reduce="title => title.key"
                  label="value"
                  :options="dataCombobox || []"
                  :placeholder="'Lựa chọn loại quy chuẩn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Hàm lượng <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="content"
                rules="required"
                :custom-messages="{required: `Hàm lượng là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.content"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Nồng độ bụi toàn phần lấy theo ca <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="fullShift"
                rules="required"
                :custom-messages="{required: `Nồng độ bụi toàn phần lấy theo calà bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.fullShift"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Nồng độ bụi toàn phần lấy theo thời điểm <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="fullTime"
                rules="required"
                :custom-messages="{required: `Nồng độ bụi toàn phần lấy theo thời điểm là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.fullTime"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Nồng độ bụi hô hấp toàn phần lấy theo ca <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="respiratoryShift"
                rules="required"
                :custom-messages="{required: `Nồng độ bụi hô hấp toàn phần lấy theo ca là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.respiratoryShift"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Nồng độ bụi hô hấp toàn phần lấy theo thời điểm <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="respiratoryTime"
                rules="required"
                :custom-messages="{required: `Nồng độ bụi hô hấp toàn phần lấy theo thời điểm là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.respiratoryTime"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDustId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        position: '',
        dustType: '',
        content: '',
        fullShift: 0,
        fullTime: 0,
        respiratoryShift: 0,
        respiratoryTime: 0,
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataDustId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          position: this.dataDustId.position,
          dustType: this.dataDustId.dustType,
          content: this.dataDustId.content,
          fullShift: this.dataDustId.fullShift,
          fullTime: this.dataDustId.fullTime,
          respiratoryShift: this.dataDustId.respiratoryShift,
          respiratoryTime: this.dataDustId.respiratoryTime,
        }
      }
    },
  },
  methods: {
    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_DUST)
      this.dataCombobox = data?.data
    },

    // action tạo yếu tố thời tiết
    handleCreateDust(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateDust', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        position: '',
        dustType: '',
        content: '',
        fullShift: 0,
        fullTime: 0,
        respiratoryShift: 0,
        respiratoryTime: 0,
      }
    },
  },
}
</script>
