var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm vị trí',"arrayExcel":[],"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.showModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataTable || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'code')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field == 'action')?_c('span',[_c('feather-icon',{staticClass:"text-body mr-1",attrs:{"icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.showModalEdit(props.row.id)}}}),_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}}),_c('edit-weather',{attrs:{"id":_vm.modalCreateClimate,"type":_vm.modalType,"dataClimateId":_vm.dataClimateId},on:{"handleCreateClimate":_vm.handleCreateClimate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }