<template>
  <div>
    <b-form class="content-form">
      <h3 class="d-flex align-items-center mb-2">
        <feather-icon
          size="20"
          icon="InfoIcon"
          class="mr-75"
        />
        <span>
          Thông tin chung
        </span>
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Mã quan trắc <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
              :custom-messages="{required: `Mã quan trắc là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="date"
          >
            <label for="code">Ngày thực hiện <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="startDate"
              rules="required"
              :custom-messages="{required: `Ngày thực hiện là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataInfo.startDate"
                :disabledInput="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="company"
          >
            <label for="company">Doanh nghiệp quan trắc <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="bussinessId"
              rules="required"
              :custom-messages="{required: `Doanh nghiệp quan trắc là bắt buộc`}"
            >
              <v-select
                v-model="dataInfo.bussinessId"
                :reduce="title => title.id"
                label="name"
                :options="dataAllBusiness || []"
                :placeholder="'Lựa chọn doanh nghiệp quan trắc'"
                :state="errors.length > 0 ? false:null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Phương pháp <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="method"
              rules="required"
              :custom-messages="{required: `Phương pháp là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.method"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Quan trắc các yếu tố có hại <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="harmfulFactors"
              rules="required"
              :custom-messages="{required: `Quan trắc các yếu tố có hại là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.harmfulFactors"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đề nghị bổ sung việc quan trắc các yếu tố có hại</label>
            <b-form-input v-model="dataInfo.supplement" />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Lý do đề xuất</label>
            <b-form-input v-model="dataInfo.reason" />
          </b-form-group>
        </b-col>
      </b-row>
      <h3 class="d-flex align-items-center mt-2 mb-2">
        <feather-icon
          size="20"
          icon="ThermometerIcon"
          class="mr-75"
        />
        <span>
          Thiết bị đo
        </span>
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo khí hậu bằng máy <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machineClimate"
              rules="required"
              :custom-messages="{required: `Đo khí hậu bằng máy là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machineClimate"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo ánh sáng bằng máy <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machineLight"
              rules="required"
              :custom-messages="{required: `Đo ánh sáng bằng máy là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machineLight"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo tiếng ồn bằng máy <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machineNoise"
              rules="required"
              :custom-messages="{required: `Đo tiếng ồn bằng máy là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machineNoise"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo bụi bằng máy <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machineDust"
              rules="required"
              :custom-messages="{required: `Đo bụi bằng máy là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machineDust"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo phóng xạ bằng máy <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machineRadioactive"
              rules="required"
              :custom-messages="{required: `Đo phóng xạ bằng máy là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machineRadioactive"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo điện từ trường bằng <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machineElectronic"
              rules="required"
              :custom-messages="{required: `Đo điện từ trường bằng là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machineElectronic"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Đo hơi khí độc bằng <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="machinePoison"
              rules="required"
              :custom-messages="{required: `Đo hơi khí độc bằng là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInfo.machinePoison"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    dataInfo: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },
  mounted() {
    this.fetchDataComboboxAllBusiness()
  },
  methods: {
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
  },
}
</script>

<style>

</style>
