<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="rule">
        <info-cpn
          v-if="isRendered('info')"
          :dataInfo="dataInfo"
          class="tab-pane"
          :class="{'active':activeTab==='info'}"
        />
        <weather-cpn
          v-if="isRendered('weather')"
          class="tab-pane"
          :class="{'active':activeTab==='weather'}"
        />
        <light-cpn
          v-if="isRendered('light')"
          class="tab-pane"
          :class="{'active':activeTab==='light'}"
        />
        <list-noise
          v-if="isRendered('Volumn2Icon')"
          class="tab-pane"
          :class="{'active':activeTab==='Volumn2Icon'}"
        />
        <list-shake
          v-if="isRendered('shake')"
          class="tab-pane"
          :class="{'active':activeTab==='shake'}"
        />
        <list-dust
          v-if="isRendered('dust')"
          class="tab-pane"
          :class="{'active':activeTab==='dust'}"
        />
        <list-posion
          v-if="isRendered('posion')"
          class="tab-pane"
          :class="{'active':activeTab==='posion'}"
        />
        <list-radation
          v-if="isRendered('radation')"
          class="tab-pane"
          :class="{'active':activeTab==='radation'}"
        />
        <list-job
          v-if="isRendered('job')"
          class="tab-pane"
          :class="{'active':activeTab==='job'}"
        />
        <ecomy
          v-if="isRendered('psychophysiological')"
          class="tab-pane"
          :dataInfo="dataInfo"
          :class="{'active':activeTab==='psychophysiological'}"
        />
        <list-other
          v-if="isRendered('other')"
          class="tab-pane"
          :class="{'active':activeTab==='other'}"
        />
      </validation-observer>
      <div class="add-business__button d-flex mt-3">
        <div class="add-business__button__item mr-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save"
          >
            Lưu lại
          </b-button>
        </div>
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="returnList"
          >
            Quay về
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoCpn from './components/Info.vue'
import WeatherCpn from './components/Weather.vue'
import LightCpn from './components/Light.vue'
import ListNoise from './components/ListNoise.vue'
import ListShake from './components/ListShake.vue'
import ListDust from './components/ListDust.vue'
import ListPosion from './components/ListPosion.vue'
import ListRadation from './components/ListRadation.vue'
import ListJob from './components/ListJob.vue'
import ListOther from './components/ListOther.vue'
import Ecomy from './components/Ecomy.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BButton,
    MyTabs,
    InfoCpn,
    WeatherCpn,
    LightCpn,
    ValidationProvider,
    ValidationObserver,
    ListNoise,
    ListShake,
    ListDust,
    ListPosion,
    ListRadation,
    ListJob,
    Ecomy,
    ListOther,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      activeTab: null,
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'weather',
          title: 'Các yếu tố khí hậu',
          icon: 'CloudLightningIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'light',
          title: 'Ánh sáng',
          icon: 'SunIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'Volumn2Icon',
          title: 'Tiếng ồn',
          icon: 'ListIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'shake',
          title: 'Rung chuyển',
          icon: 'WindIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'dust',
          title: 'Bụi các loại',
          icon: 'CloudSnowIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'posion',
          title: 'Hơi khí độc',
          icon: 'AlertTriangleIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'radation',
          title: 'Yếu tố phóng xạ, điện từ trường',
          icon: 'ZapIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'job',
          title: 'Yếu tố tiếp xúc nghề nghiệp',
          icon: 'BriefcaseIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'psychophysiological',
          title: 'Đánh giá yếu tố tâm sinh lý và éc-gô-nô-my',
          icon: 'UserCheckIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'other',
          title: 'Yếu tố khác',
          icon: 'ListIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataInfo: {
        code: '',
        startDate: '',
        bussinessId: '',
        method: '',
        harmfulFactors: '',
        supplement: '',
        reason: '',
        machineClimate: '',
        machineLight: '',
        machineNoise: '',
        machineDust: '',
        machineRadioactive: '',
        machineElectronic: '',
        machinePoison: '',
        economy: '',
      },
      dataDetail: {},
      detailId: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    dataDetail(val) {
      if (val) {
        this.dataInfo = {
          code: this.dataDetail.code,
          startDate: this.dataDetail.startDate,
          bussinessId: this.dataDetail.bussinessId,
          method: this.dataDetail.method,
          harmfulFactors: this.dataDetail.harmfulFactors,
          supplement: this.dataDetail.supplement,
          reason: this.dataDetail.reason,
          machineClimate: this.dataDetail.machineClimate,
          machineLight: this.dataDetail.machineLight,
          machineNoise: this.dataDetail.machineNoise,
          machineDust: this.dataDetail.machineDust,
          machineRadioactive: this.dataDetail.machineRadioactive,
          machineElectronic: this.dataDetail.machineElectronic,
          machinePoison: this.dataDetail.machinePoison,
          economy: this.dataDetail.economy,
        }
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.detailId = this.$route.params.id
      this.listTabs[1].isDisabled = false
      this.listTabs[2].isDisabled = false
      this.listTabs[3].isDisabled = false
      this.listTabs[4].isDisabled = false
      this.listTabs[5].isDisabled = false
      this.listTabs[6].isDisabled = false
      this.listTabs[7].isDisabled = false
      this.listTabs[8].isDisabled = false
      this.listTabs[9].isDisabled = false
      this.listTabs[10].isDisabled = false
      this.fetchDataDetail(this.detailId)
    }
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {
    // Lấy dữ liệu chi tiết
    async fetchDataDetail() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_OBSERVATION_MONITOR}${this.detailId}`)
      this.dataDetail = data
    },

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    // Thêm hoặc sửa xác nhận giấy phép
    async save() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          const data = {
            ...this.dataInfo,
          }
          if (this.$route.params.id) {
            this.$showAllPageLoading()
            await axiosApiInstance.put(ConstantsApi.EDIT_OBSERVATION_MONITOR, { ...data, id: this.$route.params.id }).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Sửa thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'environmental-monitoring' })
              } else {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
                  title: 'Thông báo',
                  variant: 'danger',
                  toaster: this.$toastPosition,
                  solid: true,
                })
              }
            })
          } else {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_OBSERVATION_MONITOR, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'environmental-monitoring' })
                this.listTabs[1].isDisabled = false
                this.listTabs[2].isDisabled = false
                this.listTabs[3].isDisabled = false
                this.listTabs[4].isDisabled = false
                this.listTabs[5].isDisabled = false
                this.listTabs[6].isDisabled = false
                this.listTabs[7].isDisabled = false
                this.listTabs[8].isDisabled = false
                this.listTabs[9].isDisabled = false
                this.listTabs[10].isDisabled = false
              } else {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
                  title: 'Thông báo',
                  variant: 'danger',
                  toaster: this.$toastPosition,
                  solid: true,
                })
              }
            })
          }
        }
      })
    },

    returnList() {
      this.$router.push({ name: 'environmental-monitoring' })
    },
  },
}
</script>
