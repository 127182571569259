var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":"Thêm vị trí","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateOther,"hidden":_vm.resetState,"show":_vm.fetchDataCombobox}},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"InfoIcon"}}),_c('span',[_vm._v(" Thông tin vị trí ")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Vị trí lao động "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"position","rules":"required","custom-messages":{required: "Vị trí lao động là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.position),callback:function ($$v) {_vm.$set(_vm.dataInput, "position", $$v)},expression:"dataInput.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"AlertTriangleIcon"}}),_c('span',[_vm._v(" Thông tin hơi khí độc ")])],1),_vm._l((_vm.dataInput.detailModals),function(item,index){return _c('div',{key:index},[(_vm.dataInput.detailModals.length > 1)?_c('span',{staticClass:"d-flex justify-content-end cursor-pointer",on:{"click":function($event){return _vm.dataInput.detailModals.splice(index, 1)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"16","icon":"XIcon"}})],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Loại khí độc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"anotherFactorId","rules":"required","custom-messages":{required: "Loại khí độc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.key; },"label":"value","options":_vm.dataCombobox || [],"placeholder":'Lựa chọn loại khí độc',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(item.anotherFactorId),callback:function ($$v) {_vm.$set(item, "anotherFactorId", $$v)},expression:"item.anotherFactorId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số mẫu đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reachedOk","rules":"required","custom-messages":{required: "Số mẫu đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(item.reachedOk),callback:function ($$v) {_vm.$set(item, "reachedOk", $$v)},expression:"item.reachedOk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số mẫu không đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reachedNot","rules":"required","custom-messages":{required: "Số mẫu không đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(item.reachedNot),callback:function ($$v) {_vm.$set(item, "reachedNot", $$v)},expression:"item.reachedNot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)}),_c('div',{staticClass:"mt-1 cursor-pointer",on:{"click":_vm.addInfoPosion}},[_c('feather-icon',{staticClass:"mr-75 text-primary",attrs:{"size":"20","icon":"PlusCircleIcon"}}),_c('span',{staticClass:"text-primary"},[_vm._v(" Thêm hơi khí độc ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }