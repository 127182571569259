<template>
  <b-modal
    :id="id"
    size="lg"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateRadation"
    @hidden="resetState"
    @show="handleCallCombobox"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin vị trí
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Vị trí lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="position"
                rules="required"
                :custom-messages="{required: `Vị trí lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.position"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="ZapIcon"
            class="mr-75"
          />
          <span>
            Yếu tố phóng xạ
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Loại quy chuẩn <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="radationLevelId"
                rules="required"
                :custom-messages="{required: `Loại quy chuẩn là bắt buộc`}"
              >
                <v-select
                  v-model="dataInput.radationLevelId"
                  :reduce="title => title.key"
                  label="value"
                  :options="dataCombobox || []"
                  :placeholder="'Lựa chọn loại quy chuẩn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Số mẫu đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="radiationReachedOk"
                rules="required"
                :custom-messages="{required: `Số mẫu đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.radiationReachedOk"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Số mẫu không đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="radiationReachedNot"
                rules="required"
                :custom-messages="{required: `Số mẫu không đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.radiationReachedNot"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-1">
          <feather-icon
            size="20"
            icon="ZapIcon"
            class="mr-75"
          />
          <span>
            Yếu tố điện từ trường
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Loại quy chuẩn <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="magneticLevelId"
                rules="required"
                :custom-messages="{required: `Loại quy chuẩn là bắt buộc`}"
              >
                <v-select
                  v-model="dataInput.magneticLevelId"
                  :reduce="title => title.key"
                  label="value"
                  :options="dataComboboxMagnetic || []"
                  :placeholder="'Lựa chọn loại quy chuẩn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Số mẫu đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="magneticReachedOk"
                rules="required"
                :custom-messages="{required: `Số mẫu đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.magneticReachedOk"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Số mẫu không đạt <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="magneticReachedNot"
                rules="required"
                :custom-messages="{required: `Số mẫu không đạt là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataInput.magneticReachedNot"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataRadationId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        radationLevelId: '',
        position: '',
        radiationReachedOk: 0,
        radiationReachedNot: 0,
        magneticLevelId: '',
        magneticReachedOk: 0,
        magneticReachedNot: 0,
      },
      dataCombobox: [],
      dataComboboxMagnetic: [],
    }
  },
  watch: {
    dataRadationId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          radationLevelId: this.dataRadationId.radationLevelId,
          position: this.dataRadationId.position,
          radiationReachedOk: this.dataRadationId.radiationReachedOk,
          radiationReachedNot: this.dataRadationId.radiationReachedNot,
          magneticLevelId: this.dataRadationId.magneticLevelId,
          magneticReachedOk: this.dataRadationId.magneticReachedOk,
          magneticReachedNot: this.dataRadationId.magneticReachedNot,
        }
      }
    },
  },
  methods: {
    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_RADATION)
      this.dataCombobox = data?.data
    },

    async fetchDataComboboxMagnectic() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_MAGNETIC)
      this.dataComboboxMagnetic = data?.data
    },

    async handleCallCombobox() {
      await this.fetchDataCombobox()
      await this.fetchDataComboboxMagnectic()
    },

    // action tạo yếu tố thời tiết
    handleCreateRadation(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateRadation', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        radationLevelId: '',
        position: '',
        radiationReachedOk: 0,
        radiationReachedNot: 0,
        magneticLevelId: '',
        magneticReachedOk: 0,
        magneticReachedNot: 0,
      }
    },
  },
}
</script>
