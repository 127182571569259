var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{staticClass:"content-form"},[_c('h3',{staticClass:"d-flex align-items-center mb-2"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"InfoIcon"}}),_c('span',[_vm._v(" Thông tin chung ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Mã quan trắc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required","custom-messages":{required: "Mã quan trắc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.code),callback:function ($$v) {_vm.$set(_vm.dataInfo, "code", $$v)},expression:"dataInfo.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"date"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Ngày thực hiện "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"startDate","rules":"required","custom-messages":{required: "Ngày thực hiện là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.startDate),callback:function ($$v) {_vm.$set(_vm.dataInfo, "startDate", $$v)},expression:"dataInfo.startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"company"}},[_c('label',{attrs:{"for":"company"}},[_vm._v("Doanh nghiệp quan trắc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"bussinessId","rules":"required","custom-messages":{required: "Doanh nghiệp quan trắc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.id; },"label":"name","options":_vm.dataAllBusiness || [],"placeholder":'Lựa chọn doanh nghiệp quan trắc',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInfo.bussinessId),callback:function ($$v) {_vm.$set(_vm.dataInfo, "bussinessId", $$v)},expression:"dataInfo.bussinessId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Phương pháp "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"method","rules":"required","custom-messages":{required: "Phương pháp là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.method),callback:function ($$v) {_vm.$set(_vm.dataInfo, "method", $$v)},expression:"dataInfo.method"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Quan trắc các yếu tố có hại "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"harmfulFactors","rules":"required","custom-messages":{required: "Quan trắc các yếu tố có hại là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.harmfulFactors),callback:function ($$v) {_vm.$set(_vm.dataInfo, "harmfulFactors", $$v)},expression:"dataInfo.harmfulFactors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đề nghị bổ sung việc quan trắc các yếu tố có hại")]),_c('b-form-input',{model:{value:(_vm.dataInfo.supplement),callback:function ($$v) {_vm.$set(_vm.dataInfo, "supplement", $$v)},expression:"dataInfo.supplement"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Lý do đề xuất")]),_c('b-form-input',{model:{value:(_vm.dataInfo.reason),callback:function ($$v) {_vm.$set(_vm.dataInfo, "reason", $$v)},expression:"dataInfo.reason"}})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center mt-2 mb-2"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"ThermometerIcon"}}),_c('span',[_vm._v(" Thiết bị đo ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo khí hậu bằng máy "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machineClimate","rules":"required","custom-messages":{required: "Đo khí hậu bằng máy là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machineClimate),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machineClimate", $$v)},expression:"dataInfo.machineClimate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo ánh sáng bằng máy "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machineLight","rules":"required","custom-messages":{required: "Đo ánh sáng bằng máy là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machineLight),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machineLight", $$v)},expression:"dataInfo.machineLight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo tiếng ồn bằng máy "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machineNoise","rules":"required","custom-messages":{required: "Đo tiếng ồn bằng máy là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machineNoise),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machineNoise", $$v)},expression:"dataInfo.machineNoise"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo bụi bằng máy "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machineDust","rules":"required","custom-messages":{required: "Đo bụi bằng máy là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machineDust),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machineDust", $$v)},expression:"dataInfo.machineDust"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo phóng xạ bằng máy "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machineRadioactive","rules":"required","custom-messages":{required: "Đo phóng xạ bằng máy là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machineRadioactive),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machineRadioactive", $$v)},expression:"dataInfo.machineRadioactive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo điện từ trường bằng "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machineElectronic","rules":"required","custom-messages":{required: "Đo điện từ trường bằng là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machineElectronic),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machineElectronic", $$v)},expression:"dataInfo.machineElectronic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Đo hơi khí độc bằng "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"machinePoison","rules":"required","custom-messages":{required: "Đo hơi khí độc bằng là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInfo.machinePoison),callback:function ($$v) {_vm.$set(_vm.dataInfo, "machinePoison", $$v)},expression:"dataInfo.machinePoison"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }